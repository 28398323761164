<template>
  <div>
    <PageHeader :title="title" :items="items" :color="'#6297C3'"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="tabs">
              <div>
                <!-- Tabs links -->
                <ul role="tablist" class="nav nav-tabs" id="__BVID__16__BV_tab_controls_">
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('all')" :activeClass="(this.$route.name == 'studentList')?'active':''">All</router-link>
                  </li>
                  <!-- <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('approval')" :activeClass="(this.$route.name == 'studentApproveList')?'active':''">Need Approval</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('no-profile')" :activeClass="(this.$route.name == 'studentNoProfileList')?'active':''">No profile</router-link>
                  </li> -->
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('search')" :activeClass="(this.$route.name == 'studentSearch')?'active':''">Search</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('search-name')" :activeClass="(this.$route.name == 'studentSearchName')?'active':''">Search By Name</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('updateCredentials')" :activeClass="(this.$route.name == 'studentUpdateCredentials')?'active':''">Update Password</router-link>
                  </li>
                </ul>
              </div>
              <!-- Content of the tab -->
              <div class="tab-content">
                <div role="tabpanel" aria-hidden="false" class="tab-pane active">
                  <slot/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../components/Page-header";


/**
 * center profile component
 */
export default {
  name: 'Student-List-Layout',
  components: {
    PageHeader,
  },
  created(){
  },
  head () {
    return {
      title: `${this.title}`,
    }
  },


  data () {
    return {
      title: 'Students-Lists',
      items: [
        {
          text: 'Work ',
          href: '/'
        },
        {
          text: 'Students',
          href: '/students/approval'
        },
        {
          text: 'Students-list',
          active: true
        }
      ],

    }
  },
  methods: {
    accountLink ($tab) {
      return '/students/' + $tab
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: flex-start;
}

.buttons {
  color: blue;
}

.QR {
  width: 50%;
  padding: 9px;
}

.Dropdown {
  position: absolute;
  right: 30px;
  margin-top: 0;

}

#sidebar-right {
  background: #fff !important;
}

.b-sidebar.b-sidebar-right {
  width: 50%;
}

header.b-sidebar-header {
  box-shadow: 2px -3px 5px;
  z-index: 999;
}

footer.b-sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  box-shadow: 2px 3px 5px;
  z-index: 999;
}

#sidebar-right___title__ {
  font-size: 20px;

  i {
    margin-right: 5px;
  }
}

.sidebar-footer {
  button {
    i {
      color: #FFF;
    }
  }
}

</style>
