<script>
import Layout from "../../layouts/main";
import StudentIndexLayout from "../../layouts/studentIndexLayout";
import errorHandler from "@/helpers/errorHandler";
import { required, helpers } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
let phoneNumberRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/)

/**
 * Starter component
 */
export default {
  page: {
    title: "Update Student Password",
  },
  head() {
    return {
      title: `Students-search`,
    };
  },
  validations: {
    phone: {
      required,
      phoneNumberRegex
    },
    username: {
      required
    },
    new_password: {
      required
    },
  },
  components: {
    Layout,
    StudentIndexLayout
  },
  methods: {
    searchStudent() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("student/updateStudentCredentials", {
            phone: this.phone,
            username: this.username,
            new_password:this.new_password
        }).then( token =>{
              if(token.detail.state == true){
                errorHandler.methods.successful('Updated successfully!');
                this.phone = '';
                this.username = '';
                this.new_password = '';
                this.submitted = null;
              }else{
                errorHandler.methods.errorValue(token.error)
              }
            }).catch((error) => {
              errorHandler.methods.errorMessage(error);
        });
      }
    },
  },
  data: () => ({
    submitted:false,
    phone: '',
    username: '',
    new_password: ''
  })
};
</script>

<template>
  <Layout>
    <StudentIndexLayout>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" @submit.prevent="searchStudent">
                    <div class="form-group mb-3">
                        <label for="Phoneid">Username</label>
                        <input
                            class="form-control"
                            v-model="username"
                            type="text"
                            id="phoneid"
                            placeholder="Enter student phone number"
                            :class="{ 'is-invalid': submitted && $v.username.$error }"
                        />
                        <div
                            v-if="submitted && $v.username.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.username.required">Username is required.</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="Phoneid">Phone</label>
                        <input
                            class="form-control"
                            v-model="phone"
                            type="text"
                            id="phoneid"
                            placeholder="Enter student phone number"
                            :class="{ 'is-invalid': submitted && $v.phone.$error }"
                        />
                        <div
                            v-if="submitted && $v.phone.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.phone.required">Phone is required.</span>
                        </div>
                        <div
                            v-if="submitted && $v.phone.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.phone.phoneNumberRegex">Please enter a phone.</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="Phoneid">New password</label>
                        <input
                            class="form-control"
                            v-model="new_password"
                            type="text"
                            id="phoneid"
                            placeholder="Enter student phone number"
                            :class="{ 'is-invalid': submitted && $v.new_password.$error }"
                        />
                        <div
                            v-if="submitted && $v.new_password.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.new_password.required">Password is required.</span>
                        </div>
                    </div>
                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-warning btn-block btn-rounded" type="submit">
                            <i class="fas fa-user-edit"></i>
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </StudentIndexLayout>
  </Layout>
</template>
